import React from "react"
import Layout from "../components/Layout"
import JournalPreview from "../components/journal-preview"
import useJournals from "../hooks/use-journals"

export default function Home() {
  const journals = useJournals()

  return (
    <>
      <Layout>
          <h1>Work Journal</h1>
          {journals.map(journal => (
            <JournalPreview key={journal.id} journal={journal}></JournalPreview>
          ))}
      </Layout>
    </>
  )
}
