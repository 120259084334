import React from "react"
import { css } from "@emotion/core"
import { MDXRenderer } from "gatsby-plugin-mdx"

const JournalPreview = ({ journal }) => {
  return (
    <article
      css={css`
        border-bottom: 1px solid #ddd;
        margin-top: 0;
        padding: 2rem 0;

        :first-of-type {
          margin-top: 1rem;
        }
        h2 {
          margin-bottom: 1em;
        }
        h3 {
          margin: 1.5em 0 1em;
        }
        ul {
          margin-left: 0;
          padding-left: 1.6rem;
        }
      `}
    >
        <h2>{journal.date}</h2>
        <MDXRenderer>{journal.body}</MDXRenderer>
    </article>
  )
}

export default JournalPreview
