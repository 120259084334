import { graphql, useStaticQuery } from "gatsby"

const useJournals = () => {
  const data = useStaticQuery(graphql`
    query {
      allMdx(
        filter: {fileAbsolutePath: {regex: "/journal/"}}
        sort: { fields: [frontmatter___date], order: DESC }
        ) {
        nodes {
          id
          frontmatter {
            date(formatString: "DD/MM/YYYY")
          }
          body
        }
      }
    }
  `)

  return data.allMdx.nodes.map(journal => ({
    title: journal.id,
    date: journal.frontmatter.date,
    body: journal.body,
  }))
}

export default useJournals
